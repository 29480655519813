html,
body {
    margin: 0;
    padding: 0;
    background-color: #eeeeee;
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: relative;

    font-family: "Delivery Normal", "Source Hans Sans CN Regular", "Source Hans Sans CN Regular", Myanmar, "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

div#root {
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}
